$(document).ready(function() {
    $(".main").removeClass("cloak");
   
    var closeNav = function() { UIkit.offcanvas.hide(); };
    $(document).on("show.uk.offcanvas", function() { $(".nav-toggle").on("click", closeNav); });
    $(document).on("hide.uk.offcanvas", function() { $(".nav-toggle").off("click", closeNav); });

    var width = $(window).width();
    L.mapbox.accessToken = 'pk.eyJ1IjoiY2FzdGxlbnJzIiwiYSI6ImNqYWE0cTAzeTBwZ3EzM3FpcXB3MHNkZG0ifQ.I5anlET1Tf523JKwEFUoHA';    
    var times = [
        [
            "6:00 am",
            "6:45 am",
            "7:30 am",
            "8:30 am",
            "9:15 am",
            "10:30 am",
            "11:15 am",
            "12:00 pm",
            "12:50 pm",
            "1:35 pm",
            "2:35 pm",
            "3:20 pm",
            "4:35 pm",
            "5:20 pm",
            "6:05 pm",
            "6:50 pm"
        ],
        [
            "7:45 am",
            "8:30 am",
            "9:30 am",
            "10:15 am",
            "11:30 am",
            "12:15 pm",
            "1:00 pm",
            "2:00 pm",
            "2:45 pm",
            "3:30 pm",            
        ]
    ];

    var departures = {
        Monday: {
            blue: times[0],
            brown: times[0],
            green: times[0],
            orange: times[0],
            purple: times[0],
            red: times[0],
            white: times[0],
            yellow: times[0]
        },
        Tuesday: {
            blue: times[0],
            brown: times[0],
            green: times[0],
            orange: times[0],
            purple: times[0],
            red: times[0],
            white: times[0],
            yellow: times[0]
        },
        Wednesday: {
            blue: times[0],
            brown: times[0],
            green: times[0],
            orange: times[0],
            purple: times[0],
            red: times[0],
            white: times[0],
            yellow: times[0]
        },
        Thursday: {
            blue: times[0],
            brown: times[0],
            green: times[0],
            orange: times[0],
            purple: times[0],
            red: times[0],
            white: times[0],
            yellow: times[0]
        },
        Friday: {
            blue: times[0],
            brown: times[0],
            green: times[0],
            orange: times[0],
            purple: times[0],
            red: times[0],
            white: times[0],
            yellow: times[0]
        },
        Saturday: {
            blue: times[1],
            brown: times[1],
            green: times[1],
            orange: times[1],
            purple: times[1],
            red: times[1],
            white: times[1],
            yellow: times[1]
        }
    };

    $("#departure-route, #departure-day").on("change", function() {
        $("#departure-times").empty();
        var route = $("#departure-route").val();
        var day = $("#departure-day").val();

        if (route.length && day.length) {
            $.each(departures[day][route], function(i, time) {
                $("#departure-times")
                    .append('<tr><td>' + time + '</td><td>' + day +'<td></tr>');
            });
        }
    });

    if ($("#bus-map").length) {
        var zoom = width < 767 ? 12 : 14;
        var map = L.mapbox.map('bus-map', 'mapbox.streets', {
            center: [37.758715, -87.111418],
            preferCanvas: true,            
            zoom: zoom
        });       
        var overlays = {};
        var routes = [
            {
                id: "blueRoute",
                name: "Blue Route",
                color: "blue",
                data: "blue.json",
                markers: []
            },
            {
                id: "brownRoute",
                name: "Brown Route",
                color: "brown",
                data: "brown.json",
                markers: []
            },
            {
                id: "greenRoute",
                name: "Green Route",
                color: "green",
                data: "green.json",
                markers: []
            },
            {
                id: "orangeRoute",
                name: "Orange Route",
                color: "orange",
                data: "orange.json",
                markers: []
            },
            {
                id: "purpleRoute",
                name: "Purple Route",
                color: "purple",
                data: "purple.json",
                markers: []
            },
            {
                id: "redRoute",
                name: "Red Route",
                color: "red",
                data: "red.json",
                markers: []
            },
            {
                id: "whiteRoute",
                name: "White Route",
                color: "gray",
                data: "white.json",
                markers: []
            },
            {
                id: "yellowRoute",
                name: "Yellow Route",
                color: "yellow",
                data: "yellow.json",
                markers: []
            }
        ];

        var stopIcon = {
            iconUrl: '/img/stop.png',
            iconSize:     [24, 24],
            iconAnchor:   [12, 12],
            popupAnchor:  [-3, -16]
        };
        
        $.each(routes, function(i, route) {
            var weight = map.getZoom() < 14 ? 2.0 : 5.0;
            $.getJSON('/js/' + route.data, function(data) {
                route.route = L.geoJson(data, {
                    style: {
                        color: route.color,
                        opacity: 1.0,
                        weight: weight
                    }
                }).addTo(map);               
                map.on('zoomend', function() {
                    if (map.getZoom() < 14) {
                        route.route.setStyle({
                            weight: 2.0
                        });
                    }
                    else {
                        route.route.setStyle({
                            weight: 5.0
                        });
                    }
                });
                $.getJSON('/js/stops/' + route.data, function(data) {
                    $.each(data.features, function(i, marker) {
                        route.markers.push(
                            L.marker([marker.geometry.coordinates[1], marker.geometry.coordinates[0]], {
                                icon: L.icon(stopIcon)
                            })
                        );
                    });
                    route.stops = L.layerGroup(route.markers);

                    map.on('zoomend', function() {
                        if (map.getZoom() < 15 && map.hasLayer(route.stops)) {
                            map.removeLayer(route.stops);
                        }

                        if (map.getZoom() > 14 && !map.hasLayer(route.stops)) {
                            map.addLayer(route.stops);
                        }
                    });
                });
            });
        });
    }

    if ($("#trolley-map").length) {
        var zoom = width < 767 ? 15 : 16;       
        var map = L.mapbox.map('trolley-map', 'mapbox.streets', {
            center: [37.7736744, -87.112989],
            preferCanvas: true,
            zoom: zoom           
        });                   
        var starIcon = {
            iconUrl: '/img/star-green.png',
            //shadowUrl: 'img/star-shadow.png',
            iconSize:     [24, 24],
            //shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [12, 12],
            //shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -16]
        };

        $.getJSON('/js/trolley.json', function(data) {
            L.geoJson(data, {
                pointToLayer: function(feature, latlng) {
                    marker = new L.marker(latlng, {icon: L.icon(starIcon)});
                    return marker;
                },
                style: {
                    color: '#921926',
                    weight: 4.0,
                    opacity: 1.0
                }
            }).addTo(map);
        });
    }
});
